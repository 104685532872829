import React from "react"
import CardItem from "./CardItem"

const HomeCard = props => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-3">
      {props.homepageCards.map((cardItem, i) => (
        <CardItem key={i} item={cardItem} count={i + 1}></CardItem>
      ))}
    </div>
  )
}

export default HomeCard
