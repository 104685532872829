import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import HomeGrid from "../components/HomeGrid"
import HomeCard from "../components/HomeCard"
import HomeSwiper from "../components/HomeSwiper"

export const query = graphql`
  query($locale: [GRAPHCMS_Locale!]!) {
    gcms {
      homepageGrids(locales: $locale, orderBy: createdAt_ASC) {
        id
        title
        slug
        gridImages(locales: en) {
          url
        }
        sliderImage(locales: en) {
          url
        }
      }

      homepageCards(locales: $locale) {
        title
        description
        id
        cardImage(locales: en) {
          url
        }
        slug
      }
    }
  }
`

const Index = ({ data }) => (
  <Layout>
    <HomeSwiper swiperItems={data.gcms.homepageGrids} />
    <HomeGrid gridItems={data.gcms.homepageGrids} />
    <div className="hidden md:block mt-8 mb-2 border-black border-t mx-3 lg:mx-7 border-dashed "></div>
    <HomeCard homepageCards={data.gcms.homepageCards} />
  </Layout>
)

export default Index
