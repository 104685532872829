import React from "react"
import { Link } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.scss"
import "swiper/components/navigation/navigation.scss"
import "swiper/components/pagination/pagination.scss"
import "swiper/components/scrollbar/scrollbar.scss"
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper"

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

const HomeSwiper = props => {
  const swiperItems = props.swiperItems
  const params = {
    pagination: {
      el: ".swiper-pagination-test",
      clickable: true,
      renderBullet: (index, className) => {
        return (
          '<img src="/img/Slider_indicator-02.svg"  class=" bullet-responsive ' +
          className +
          '"></img>'
        )
      },
    },
    spaceBetween: 50,
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    scrollbar: { draggable: true },
  }

  return (
    <div className="block lg:hidden dir-ltr">
      <Swiper {...params}>
        {swiperItems.map((swiperItem, i) => (
          <SwiperSlide key={i}>
            <div className="mx-3 lg:mx-7 relative content-center">
              <Link to={swiperItem.slug}>
                <img
                  alt="slider"
                  src={swiperItem.sliderImage.url}
                  className="object-fill h-90 w-full "
                ></img>
                <div className="flex absolute bottom-0 items-center h-14 bg-image-layover w-full">
                  <div className="text-white ml-6">{swiperItem.title}</div>
                </div>
              </Link>
            </div>
          </SwiperSlide>
        ))}

        <div className="dashed-separator mx-3 mb-5 mt-1 swiper-pagination-test"></div>
      </Swiper>
    </div>
  )
}
export default HomeSwiper
