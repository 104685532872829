import React, { useState } from "react"
import { Transition } from "@headlessui/react"
import { Link } from "gatsby"

const HomeGrid = props => {
  const [isShownGridOne, setIsShownGridOne] = useState(false)
  const [isShownGridTwo, setIsShownGridTwo] = useState(false)
  const [isShownGridThree, setIsShownGridThree] = useState(false)
  const [isShownGridFour, setIsShownGridFour] = useState(false)
  const [isShownGridFive, setIsShownGridFive] = useState(false)
  const [isShownGridSix, setIsShownGridSix] = useState(false)
  const gridRow1 = []
  const gridRow2 = []
  const gridItems = props.gridItems

  function hoverComponent(gridItem, gridState) {
    return (
      <Transition
        show={gridState}
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="flex absolute bottom-0 items-center h-14 bg-image-layover w-full">
          <div className="text-white m-6 ">{gridItem.title}</div>
        </div>
      </Transition>
    )
  }

  function hoverComponentFull(gridItem, gridState) {
    return (
      <Transition
        show={gridState}
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="flex absolute inset-0 items-end bg-image-layover-full h-full">
          <p className=" text-white m-5 ">{gridItem.title}</p>
        </div>
      </Transition>
    )
  }
  for (let i = 0; i < 5; i++) {
    const imagesCount = gridItems[0].gridImages.length-1
    gridRow1.push(
      <Link
        key={i}
        to={gridItems[0].slug}
        onMouseEnter={() => setIsShownGridOne(true)}
        onMouseLeave={() => setIsShownGridOne(false)}
        className=" relative col-span-1"
      >
        <img
          src={typeof (gridItems[0].gridImages[i]) === 'undefined' ? gridItems[0].gridImages[imagesCount].url : gridItems[0].gridImages[i].url }
          alt={gridItems[0].title}
          className=" w-full h-full"
        />

        {i === 0
          ? hoverComponentFull(gridItems[0], isShownGridOne)
          : ""}
      </Link>
    )
  }
  for (let i = 0; i < 4; i++) {
    const imagesCount = gridItems[1].gridImages.length-1
    gridRow2.push(
      <Link
        key={i}
        to={gridItems[1].slug}
        onMouseEnter={() => setIsShownGridTwo(true)}
        onMouseLeave={() => setIsShownGridTwo(false)}
        className=" relative col-span-1"
      >
        <img
          src={typeof (gridItems[1].gridImages[i]) === 'undefined' ? gridItems[1].gridImages[imagesCount].url : gridItems[1].gridImages[i].url }
          alt={gridItems[1].title}
          className=" w-full h-full"
        />
        {i === 3
          ? hoverComponentFull(gridItems[1], isShownGridTwo)
          : ""}
      </Link>
    )
  }

  return (
    <div className="hidden lg:grid grid-cols-7 grid-rows-4 gap-1 text-sm mx-3 lg:mx-7">
      {gridRow1}

      <Link
        to={gridItems[3].slug}
        onMouseEnter={() => setIsShownGridThree(true)}
        onMouseLeave={() => setIsShownGridThree(false)}
        className=" relative col-span-2"
      >
        <img
          src={gridItems[3].gridImages[0].url}
          alt={gridItems[3].title}
          className=" w-full h-full"
        />
        {hoverComponent(gridItems[3], isShownGridThree)}
      </Link>

      {gridRow2}

      <Link
        to={gridItems[2].slug}
        onMouseEnter={() => setIsShownGridFour(true)}
        onMouseLeave={() => setIsShownGridFour(false)}
        className="relative col-span-3 row-span-3"
      >
        <img
          className=" w-full h-full"
          src={gridItems[2].gridImages[0].url}
          alt={gridItems[2].title}
        />

        {hoverComponent(gridItems[2], isShownGridFour)}
      </Link>
      <Link
        to={gridItems[4].slug}
        onMouseEnter={() => setIsShownGridFive(true)}
        onMouseLeave={() => setIsShownGridFive(false)}
        role="none"
        className="relative col-span-2 row-span-2"
      >
        <img
          className=" w-full h-full"
          src={gridItems[4].gridImages[0].url}
          alt={gridItems[4].title}
        />
        {hoverComponent(gridItems[4], isShownGridFive)}
      </Link>
      <Link
        to={gridItems[5].slug}
        onMouseEnter={() => setIsShownGridSix(true)}
        onMouseLeave={() => setIsShownGridSix(false)}
        role="none"
        className="relative col-span-2 row-span-2"
      >
        <img
          className=" w-full h-full"
          src={gridItems[5].gridImages[0].url}
          alt={gridItems[5].title}
        />
        {hoverComponent(gridItems[5], isShownGridSix)}
      </Link>
    </div>
  )
}

export default HomeGrid
